const autoNgTemplateLoaderTemplate1 = require('./ldap-settings.html');

import controller from './ldap-settings.controller';

export const ldapSettings = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
  bindings: {
    settings: '=',
    state: '<',
    connectivityCheck: '<',
  },
};
