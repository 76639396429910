const autoNgTemplateLoaderTemplate1 = require('./ad-settings.html');

import controller from './ad-settings.controller';

export const adSettings = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
  bindings: {
    settings: '=',
    tlscaCert: '=',
    state: '=',
    connectivityCheck: '<',
  },
};
